<template>
  <div>
    <p v-if="isLoading">Loading...</p>
    <section
      class="input-unit"
      :class="{
        'hide-border': hideBorder,
        'unset-max-width': unsetMaxWidth,
        'bold': bold
      }"
      v-if="!isLoading"
    >
      <b-field>
        <b-autocomplete
          field="name"
          :placeholder="disabled ? '' : 'Pilih Satuan'"
          :clearable="!disabled"
          open-on-focus
          v-model="unitName"
          :data="filteredData"
          :disabled="disabled"
          @focus="resetSearch"
          @select="(option) => handleSelectUnit(option)"
          @input="(e) => handleChangeInput(e)"
        ></b-autocomplete>
      </b-field>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'

export default {
  name: 'InputAutocompleteSatuan',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String],
      default: null
    },
    hideBorder: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    unsetMaxWidth: {
      type: Boolean,
      default: false
    },
    idAutocomplete: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      unitName: '',
      search: '',
      dataUnit: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      reset: 'unitPrice/getResetUnitPrice',
      detailUnitPrice: 'unitPrice/getDetailUnitPrice'
    }),
    filteredData () {
      return this.dataUnit.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) >= 0
        )
      })
    }
  },
  methods: {
    handleChangeInput (e) {
      this.search = e || ''
    },
    handleSelectUnit (option) {
      this.$emit('selectedUnit', option || 'empty')
    },
    resetSearch () {
      this.search = ''
    },
    getDataUnit () {
      this.isLoading = true
      const payload = {
        url: this.url
      }
      this.$store
        .dispatch('project/getListUnit', payload)
        .then((response) => {
          this.isLoading = false
          this.dataUnit = response.data.data
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    updatedValue (isStore) {
      if (this.value && this.value !== 'empty') {
        this.unitName = this.value.name
        const option = {
          id: this.value.id,
          name: this.value.name
        }
        if (isStore) {
          this.handleSelectUnit(option)
        }
      }
    }
  },
  mounted () {
    this.updatedValue(true)
  },
  watch: {
    value: {
      handler (val) {
        if (val) {
          this.unitName = val.name
        }
      }
    },
    reset (val) {
      this.unitName = ''
    },
    detailUnitPrice: {
      handler (val) {
        if (this.idAutocomplete === `${val.index}-${val.code.replace(/^edit_/, '')}`) {
          this.unitName = val.unit_name || ''
          const option = {
            id: val.unit_id || null,
            name: val.unit_name || null
          }
          if (option.id && option.name) {
            this.handleSelectUnit(option)
          }
        }
      },
      deep: true
    }
  },
  created () {
    this.getDataUnit()
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.input-unit input {
  border-radius: 10px !important;
  border: 1px solid $color-grey-2 !important;
  font-size: 15px;
}

.input-unit.hide-border input {
  border: none !important;
}
</style>

<style>
.input-unit.unset-max-width .autocomplete .dropdown-menu {
  max-width: unset !important;
}

.input-unit .autocomplete .dropdown-menu {
  z-index: 22;
}

.input-unit.hide-border .autocomplete input {
  background: white !important;
}

.input-unit.bold .autocomplete input {
  font-family: 'Averta-Bold' !important;
  color: #474747;
}
</style>
